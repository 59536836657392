//
// apexcharts.scss
//
.apex-charts {
    min-height: 10px !important;

    text {
        font-family: $font-family-base !important;
    }

    .apexcharts-canvas {
        margin: 0 auto;
    }
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
    font-family: $font-family-base !important;
}

.apexcharts-tooltip * {
    font-family: $font-family-base !important;
    color: $gray-600 !important;
}

.apexcharts-tooltip.apexcharts-theme-dark {
    * {
        color: $white !important;
    }
}

.apexcharts-legend-series {
    font-weight: $font-weight-medium;
}

.apexcharts-gridline {
    pointer-events: none;
    stroke: $apex-grid-color;
}

.apexcharts-legend-text {
    color: $gray-600 !important;
    font-family: $font-family-base !important;
    font-size: 13px !important;
}

.apexcharts-pie-label {
    fill: $white !important;
}

.apexcharts-title-text,
.apexcharts-subtitle-text {
    fill: $gray-500;
}

.apexcharts-yaxis,
.apexcharts-xaxis {
    text {
        font-family: $font-family-base !important;
        fill: $gray-500;
    }
}

.apexcharts-yaxis-title {
    font-weight: 500;
}

// column dynamic loaded chart

#dynamicloadedchart-wrap {
    margin: 0px auto;
    max-width: 800px;
    position: relative;
}

.chart-box {
    padding-left: 0;
}

#chart-year,
#chart-quarter {
    width: 96%;
    max-width: 48%;
    box-shadow: none;
    padding-left: 0;
    padding-top: 20px;
    background: $card-bg;
    border: 1px solid var(--#{$prefix}border-color-translucent);
}

#chart-year {
    float: left;
    position: relative;
    transition: 1s ease transform;
    z-index: 3;

    &.chart-quarter-activated {
        transform: translateX(0);
        transition: 1s ease transform;
    }
}

#chart-quarter {
    float: left;
    position: relative;
    z-index: -2;
    transition: 1s ease transform;

    &.active {
        transition: 1.1s ease-in-out transform;
        transform: translateX(0);
        z-index: 1;
    }
}

@media screen and (min-width: 480px) {
    #chart-year {
        transform: translateX(50%);
    }

    #chart-quarter {
        transform: translateX(-50%);
    }
}


body[data-bs-theme="dark"] {

.apexcharts-gridline {
    stroke: var(--#{$prefix}border-color-translucent) !important;
}

.apexcharts-grid-borders {

    line {
        stroke: var(--#{$prefix}border-color-translucent) !important;
    }

    .apexcharts-gridline {
        stroke: var(--#{$prefix}border-color-translucent) !important;
    }
}

}