.fc td,
.fc th {
    border: $table-border-width solid $table-border-color;
}

.fc {
    .fc-toolbar {
        h2 {
            font-size: 16px;
            line-height: 30px;
            text-transform: uppercase;
        }

        @media (max-width: 767.98px) {

            .fc-left,
            .fc-right,
            .fc-center {
                float: none;
                display: block;
                text-align: center;
                clear: both;
                margin: 10px 0;
            }

            >*>* {
                float: none;
            }

            .fc-today-button {
                display: none;
            }
        }

        .btn {
            text-transform: capitalize;
        }

    }

    .fc-col-header-cell {
        background-color: $light;
    }

    .fc-col-header-cell-cushion {
        display: block;
        padding: 8px 4px;
    }

    .fc-daygrid-day-number {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: $font-weight-medium;
        margin: 2px;
    }

    .fc-daygrid-day.fc-day-today {
        background-color: rgba($primary, 0.1);

        .fc-daygrid-day-number {
            background-color: $primary;
            color: $white;
        }
    }

    .fc-list-event:hover td {
        background: transparent;
    }

    .fc-list-event-dot {
        border-color: $white;
    }

    .fc-list-event-title {
        a {
            color: $white !important;
        }
    }

    .fc-col-header,
    .fc-daygrid-body,
    .fc-scrollgrid-sync-table {
        width: 100% !important;
    }
}

.fc-theme-bootstrap a:not([href]) {
    color: $body-color;
}

.fc-theme-standard .fc-list-day-cushion {
    background-color: var(--#{$prefix}tertiary-bg) !important;
}

.fc-event {
    color: $white;
}


.fc {
    th.fc-widget-header {
        background: $gray-300;
        color: $gray-700;
        line-height: 20px;
        padding: 10px 0;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }
}

.fc-unthemed {

    .fc-content,
    .fc-divider,
    .fc-list-heading td,
    .fc-list-view,
    .fc-popover,
    .fc-row,
    tbody,
    td,
    th,
    thead {
        border-color: $gray-300;
    }

    td.fc-today {
        background: lighten($gray-200, 2%);
    }
}

.fc-button {
    background: $primary;
    border-color: var(--#{$prefix}border-color);
    color: $gray-700;
    text-transform: capitalize;
    box-shadow: none;
    padding: 6px 12px !important;
    height: auto !important;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    background-color: $primary;
    color: $white;
    text-shadow: none;
}

.fc-event {
    border-radius: 2px;
    border: none;
    cursor: move;
    font-size: 0.8125rem;
    margin: 5px 7px;
    padding: 5px 5px;
    text-align: center;
}

.fc-event,
.fc-event-dot {
    background-color: $primary;
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
    background-color: $primary;
}

.fc-daygrid-dot-event {
    color: $white !important;

    .fc-event-title {
        font-weight: $font-weight-semibold;
    }
}

.fc-daygrid-event-dot {
    border-color: $white !important;
    color: $white !important;
}

.fc-event .fc-content {
    color: $white;
}

#external-events {
    .external-event {
        text-align: left;
        padding: 8px 16px;
        margin: 6px 0;
    }
}


.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.bg-dark {
    .fc-content {
        color: $light;
    }
}


// RTL
[dir="rtl"] {
    .fc-header-toolbar {
        direction: ltr !important;
    }

    .fc-toolbar>*>:not(:first-child) {
        margin-left: .75em;
    }
}

.fc-toolbar {
    @media (max-width: 575.98px) {
        flex-direction: column;
        gap: 16px;
    }
}

.fc-button-group {
    .fc-button-primary {
        background-color: #3980c0 !important;
        border: #3980c0 !important;

        &:focus,
        &:active {
            box-shadow: 0 0 0 0.2rem rgba(57, 129, 192, 0.58) !important;
        }
    }
}

.fc-theme-standard .fc-list {
    border: 1px solid var(--#{$prefix}border-color);
}

.fc .fc-button-primary:disabled {
    background-color: #3980c0;
    border: #3980c0;
}

.fc .fc-button-primary {
    background-color: #3980c0 !important;
    border: #3980c0 !important;
}


[data-bs-theme="dark"] {
    .fc .fc-col-header-cell {
        background-color: var(--#{$prefix}tertiary-bg) !important;
    }

    .fc-theme-standard .fc-scrollgrid {
        border: 1px solid var(--#{$prefix}border-color) !important;
    }

    .fc-daygrid-event {
        &.bg-dark {
            background-color: #072435 !important;
        }
    }

    .fc-event-future{
        &.bg-dark{
            background-color: var(--#{$prefix}tertiary-bg) !important;
            color: $white !important;
        }

    }
}