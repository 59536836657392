// 
// ecommerce.scss
//

// product

.search-box{
    .form-control{
        border-radius: 30px;
        padding-left: 40px;
    }
    .search-icon{
        font-size: 16px;    
        position: absolute;
        left: 13px;
        top: 2px;
        color: $gray-600;
        line-height: 38px;
    }
}   


.categories-list{
    padding: 4px 0;
    li{
        a{
            display: block;
            padding: 4px 12px;
            color: $body-color;
        }

        &.active{
            a{
                color: $primary;
            }
        }
    }
}


// product box

.product-box{
    border: 1px solid var(--#{$prefix}border-color);
    transition: all 0.4s;
    overflow: hidden;
    margin-top: 20px;
    cursor: pointer;
    position: relative;
    
    &:hover{
        box-shadow: $box-shadow;
        .product-color{
            visibility: visible;
        }
    }

    .product-ribbon{
    position: absolute;
    background-color: $primary;
    color: $white;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 70px;
    top: -2px;
    transform: rotate(-90deg) translateX(-27%);
    font-weight: $font-weight-medium;
    &:after{
        content: "";
        border-top: 18px solid $primary;
        border-bottom: 18px solid $primary;
        border-right: 11px solid transparent;
        border-left: 11px solid transparent;
        position: absolute;
        left: -11px;
        top: 0;
    }
    }
}



.product-wishlist{
    position: absolute;
    top: 20px;
    right: 30px;
    z-index: 9;
    a{
        display: inline-block;
        width: 40px;
        height: 40px;
        border: 2px solid var(--#{$prefix}border-color);
        line-height: 36px;
        border-radius: 50%;
        text-align: center;
        color: $gray-500;
    }
}

.modal-button{
    position: absolute;
    right: 0;
    z-index: 2;

   @media (max-width: 576px) {
       position: inherit;
   }
}

.product-content{
    position: relative;
    transition: all 0.2s;
}

// product-detail

.product-detail{

    .swiper-button-next,
    .swiper-button-prev{
        color: $primary;
        width: 35px;
        height: 35px;
        background: rgba($primary, 0.3);
        border-radius: 50%;
        &:after{
           font-size: 20px;
        }
    }

    .swiper-button-next{
        right: 20px;
        left: auto;
    }

    .swiper-button-prev{
        left: 20px;
        right: auto;
    }

    .swiper-slide-thumb-active{
        background-color: var(--#{$prefix}tertiary-bg);
    }

    .swiper-slide{
        padding: 5px 15px;
        border-radius: 5px;
    }
}

.product-desc-list{
    li{
        padding: 2px 0px;
    }
}

.product-desc-color{
    a{
        display: inline-block;
        text-align: center;
        color: $body-color;
        margin: 4px;
        border: 2px solid var(--#{$prefix}border-color);
        border-radius: 4px;

        &.active, &:hover{
            color: $primary;
            border-color: $primary !important;
        }
    }
}


.product-review-link{
    .list-inline-item{
        a{
            color: $gray-600;
            padding: 4px 8px;
            font-size: 15px;
        }

    }           
}

// ecommerce cart

.product-cart-touchspin{
    border: 1px solid $input-border-color;
    background-color: $input-bg;
    border-radius: $border-radius;
    .form-control{
        border-color: transparent;
        height: 32px
    }
    
    .input-group-btn .btn{
        background-color: transparent !important;
        border-color: transparent !important;
        color: $primary !important;
        font-size: 16px;
        padding: 3px 12px;
        box-shadow: none;
    }

}


// ecommerce checkout

.shipping-address{
    box-shadow: none;
    &.active{
        border-color: $primary !important;
    }
}

.product-tag {
    position: absolute;
    top: 0;
    z-index: 9;
    right: 0;
    width: 100%;
    display: block;
    font-size: 15px;
    padding: 0;
    overflow: hidden;
    height: 100px;
    .badge {
      float: right;
      transform: rotate(45deg);
      right: -67px;
      top: 17px;
      position: relative;
      text-align: center;
      width: 200px;
      font-size: 13px;
      margin: 0;
      padding: 7px 10px;
      font-weight: 500;
      color: $primary;
      background: $white;
    }
}

// body[data-layout-mode="dark"] {
//     .product-box{
//         border: 1px solid lighten($gray-dark-200,4%);
//     }

//     .product-detail{
//         .swiper-slide-thumb-active{
//             background-color: $gray-dark-300;
//         }
//     }

//     .search-box{
//         .search-icon{
//             color: $gray-dark-600;
//         }
//     }

//     .categories-list{
//         li{
//             a{
//                 color: $gray-dark-400
//             }
//         }
//     }

//     .product-desc-color {
//         a{
//             border: 2px solid $gray-dark-300;
//         }
//     }

//     .product-wishlist{
//         a{
//             border: 2px solid lighten($gray-dark-200, 4%);
//             color: $gray-dark-400;
//         }
//     }
    
// }